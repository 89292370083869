<template>
  <div>
    <el-cascader
        :options="options"
        style="width:100%"
       v-model="_value"
       :filterable="true"
       :props="{
                label:'name',
                value:'value',
                emitPath:false,
                children:'children',
                checkStrictly:true
               }"
       size="medium"
       clearable>
    </el-cascader>
  </div>
</template>

<script>
import {Arrays} from "@/utils/Arrays";

export default {
  name: "userAndDeptCascader",
  props: {
    value:{
      type:String | Number,
      default(){
        return 0
      }
    },
    filterShop:{
      type:Boolean,
      default(){
        return false
      }
    }
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      options: [],
    };
  },
  created() {
    this.$api.sysapi.marketPeopleTree().then(({ code, data }) => {
      this.options = new Arrays().map(data.children, (item) => {
        item.children && !item.children.length && item.userId && delete item.children;
        item.value = (item.userId || 'd-'+item.id)+'' ;
        item.name = item.userName || item.deptName;
        return item;
      }).filter(dept=> (!this.filterShop||(this.filterShop&&dept.deptName!='商户')))
      localStorage.setItem('userAndDeptCascader',JSON.stringify(this.options))
    });
  },
}
</script>

<style scoped>

</style>