<template>
  <div class="edit-wrapper">
    <div class="form-content-wrapper">
      <div class="form-content">
        <el-form :model="tourForm" ref="tourForm" :rules="tourFormRules" label-width="120px" size="medium">
          <el-form-item label="问题类型" prop="typeId">
            <el-cascader :options="tourTypes"
                         v-model="tourForm.typeId"
                         :props="{
                           ...optionProps,
                           label:'typeName'
                         }"
                         size="medium"
                         @change="getDefaultUser">
            </el-cascader>
          </el-form-item>
          <el-form-item label="巡场区域" prop="areaId">
            <el-cascader :options="tourAreas"
                         v-model="tourForm.areaId"
                         :props="{
                           ...optionProps,
                           label:'areaName'
                         }"
                         size="medium">
            </el-cascader>
          </el-form-item>
          <el-form-item label="受理部门/人" prop="handleUserId">
            <user-and-dept-cascader v-model="tourForm.handleUserId" :filter-shop="true"></user-and-dept-cascader>
            <div class="default-checkbox">
              <el-checkbox v-model="isDefaultUser" :disabled="!tourForm.typeId" @change="changeUseDefault">默认受理部门/人</el-checkbox>
            </div>

          </el-form-item>

          <el-form-item label="预期完成时间" prop="estimateDate">
            <el-date-picker v-model="tourForm.estimateDate"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"></el-date-picker>
          </el-form-item>

          <el-form-item label="详细位置" prop="address">
            <el-input v-model="tourForm.address"
                      type="textarea"
                      maxlength="80"
                      placeholder="请输入"></el-input>
          </el-form-item>

          <el-form-item label="巡场描述" prop="description">
            <el-input v-model="tourForm.description"
                      type="textarea"
                      maxlength="80"
                      placeholder="请输入"></el-input>
          </el-form-item>

          <el-form-item label="上传图片/视频" prop="resources" style="width: 480px">
            <d-upload :file-list.sync="tourForm.resources" :limit="10" :multiple-preview="true"></d-upload>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="occupy-ele"></div>
    <div class="button-group fixed-bottom-btn">
      <el-button class="btn-default" size="medium" @click="back">返回</el-button>
      <el-button class="btn-submit mgl20" size="medium" type="primary" @click="confirm(true)">匿名提交</el-button>
      <el-button class="btn-submit mgl20" size="medium" type="primary" @click="confirm(false)">提交</el-button>
    </div>
  </div>
</template>

<script>
import UserAndDeptCascader from "@/components/userAndDeptCascader";
import {deepClone} from "@/components/dynamicForm/utils/utils";
import DUpload from "@/components/d-upload";
import {flattenList,removeLeaf} from "@/utils/utils";
export default {
  name: "tour-work-order-edit",
  components: {DUpload, UserAndDeptCascader},
  data(){
    let validateFile = (rule,value,callback)=>{
      if(this.tourForm.resources.length===0){
        callback(new Error('不能为空'))
      }else{
        callback()
      }
    }
    return {
      tourTypes:[],
      tourAreas:[],
      tourForm:{
        typeId:'',
        areaId:'',
        address:'',
        estimateDate:'',
        description:'',
        handleDeptId:'',
        handleUserId:'',
        resources:[]
      },
      optionProps:{
        value:'id',
        emitPath:false,
        children:'child',
        checkStrictly:true
      },
      tourFormRules:{
        typeId:[this.$formValidator.empty("问题类型",['change','blur'],'number')],
        areaId:[this.$formValidator.empty("巡场区域",['change','blur'],'number')],
        estimateDate:[this.$formValidator.empty("预期完成时间",'change')],
        description:[this.$formValidator.empty("巡场描述",'blur')],
        address:[this.$formValidator.empty("地址",'blur',)],
        handleUserId: [this.$formValidator.empty('受理部门/人',['change','blur'],'string')],
        resources:[{required:true,validator:validateFile,trigger:['blur','change']}]
      },
      pickerOptions:{
        disabledDate(time){
          let year = new Date().getFullYear()
          let endTime = new Date().setFullYear(year+1);
          return (time.getTime() < Date.now()-24*60*60*1000) || (time.getTime() > endTime)
        }
      },
      defaultHandleUserId:''
    }
  },
  computed:{
    isDefaultUser:{
      get(){
        return this.defaultHandleUserId?this.defaultHandleUserId==this.tourForm.handleUserId:false
      },
      set(val){
        this.$emit('input',val)
      }
    }
  },
  mounted(){
    this.getTourTypes();
    this.getTourAreas();
  },
  methods:{
    // 获取报修类型
    getTourTypes(){
      this.$api.tour.typeConfs().then(({data})=>{
        this.tourTypes = removeLeaf(data,'child')
      })
    },

    // 获取报修区域
    getTourAreas(){
      this.$api.tour.areaConfs().then(({data})=>{
        this.tourAreas = removeLeaf(data,'child')
      })
    },

    back(){
      this.$router.go(-1)
    },

    confirm(tag){
      let param = deepClone(this.tourForm);
      if(~(param.handleUserId.toString().indexOf('d-'))) {
        let userAndDeptCascader = flattenList(JSON.parse(localStorage.getItem('userAndDeptCascader')),'children')
        let hasArr = []
        hasArr = userAndDeptCascader.filter(item=>{
          return item.value == param.handleUserId.toString()
        })
        if(hasArr.length==0){
          this.$message.warning('当前场馆下未关联相关部门!');
          return
        }
        param.handleDeptId = this.tourForm.handleUserId.substr(2);
        param.handleUserId=''
      }
      param.isAnonymous = tag
      this.$refs.tourForm.validate((valid)=>{
        if(valid){
          this.$api.tour.postOrder(param).then(data=>{
            this.$message.success('提交成功');
            this.back();
          })
        }
      })
    },

    // 选中类型之后，自动选择该类型下的默认报修部门/人
    getDefaultUser(typeId){
      if(!typeId) return ;
      let selectType = flattenList(this.tourTypes,'child').filter(e=>e.id===typeId)[0];
      this.defaultHandleUserId = (selectType.userId||'d-'+selectType.deptId)+'' ;
      this.tourForm.handleUserId = this.defaultHandleUserId;
    },

    changeUseDefault(isChecked){
      if(isChecked){
        this.tourForm.handleUserId = this.defaultHandleUserId;
      }else{
        this.tourForm.handleUserId = '';
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/pages/style/edit-common";
.form-content{
  padding: 30px 20px 10px 20px;
}
::v-deep .el-form-item__content{
  width: 360px;
}
.form-content-wrapper{
  min-height: 706px;
}
.el-form-item__content > div{
  width: 100%;
}
.default-checkbox{
  position: absolute;
  display: inline-block;
  right: -122px;
  width: auto !important;
  top: 0;
}
</style>